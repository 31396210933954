import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Paper,
} from "@mui/material";

function Calculator() {
  const [cost, setCost] = useState("");

  const [margin, setMargin] = useState("40.1");
  const [markup, setMarkup] = useState("");
  const [revenue, setRevenue] = useState("");
  const [profit, setProfit] = useState("");

  const handleCalculate = () => {
    const costNum = parseFloat(cost);

    const markupPercentage = (margin / (100 - margin)) * 100;
    const revenue = costNum * (1 + markupPercentage / 100);
    const profit = revenue - cost;

    setMarkup(`${markupPercentage.toFixed(2)}%`);
    setRevenue(`$${revenue.toFixed(2)}`);
    setProfit(`$${profit.toFixed(2)}`);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh", textAlign: "center", marginTop: "25px" }}
    >
      <Paper elevation={16} style={{ padding: "25px" }}>
        <Grid item>
          <img
            src="https://leofilestorage.blob.core.windows.net/public-container/Images/new/leonard-regular-transparent.png"
            alt="Leonard USA Logo"
            style={{ marginTop: "25px", width: "200px", marginBottom: "24px" }}
          />
          <Typography variant="h4" style={{ marginBottom: "24px" }}>
            Margin Calculator
          </Typography>
          <FormControl>
            <InputLabel htmlFor="outlined-adornment-amount">Cost</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              label="Cost"
              type="number"
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              style={{ margin: "8px" }}
            />
          </FormControl>
          <TextField
            label="Margin (%)"
            variant="outlined"
            type="number"
            value={margin}
            onChange={(e) => setMargin(e.target.value)}
            style={{ margin: "8px" }}
          />
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCalculate}
              style={{ margin: "15px" }}
            >
              Calculate
            </Button>
          </div>

          {markup && (
            <div style={{ marginTop: "24px" }}>
              {/* <Typography variant="h5">Markup: {markup}</Typography> */}
              <Typography variant="h5">Revenue: {revenue}</Typography>
              <Typography variant="h5">Profit: {profit}</Typography>
            </div>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}

export default Calculator;
